import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import FadeOutContainer from '@zego/components/atoms/FadeOutContainer';
import ScopedNotification, {
  UiStatus,
} from '@zego/components/ScopedNotification';

interface LogoutConfirmationNotificationProps {
  message?: string;
}
export default function LogoutConfirmationNotification({
  message = 'You have successfuly logged out',
}: LogoutConfirmationNotificationProps): JSX.Element {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(true);
  setTimeout(() => {
    setShowLogoutConfirmation(false);
  }, 4000);
  return (
    <FadeOutContainer>
      <CSSTransition
        in={showLogoutConfirmation}
        appear={true}
        unmountOnExit
        classNames="fade"
        timeout={300}
      >
        {/* @ts-expect-error: BC2-452 added this placeholder error description */}
        <ScopedNotification
          message={message}
          status={UiStatus.Success}
          css={{
            transition: 'all 200ms ease-in-out',
            opacity: 1,
            justifyContent: 'center',
            borderRadius: 0,
            p: {
              margin: 0,
              color: 'inherit',
            },
          }}
        />
      </CSSTransition>
    </FadeOutContainer>
  );
}
