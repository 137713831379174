import styled from '@emotion/styled';

import View from '@zego/components/atoms/View';

const Container = styled(View)`
.fade-enter{
    opacity: 0;
  }
.fade-exit{
    opacity: 1;
  }
.fade-enter-active{
    opacity: 1;
  }
.fade-exit-active{
    opacity: 0;
  }
.fade-enter-active,
.fade-exit-active{
    transition: opacity 500ms;
  }`;

export default Container;
