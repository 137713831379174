import * as React from 'react';
import { ReactSVG } from 'react-svg';

import VisuallyHidden from '@reach/visually-hidden';

import {
  errorColor,
  infoColor,
  successColor,
  unit,
  warningColor,
} from '@zego/theme';

import { UiStatus } from './enums';

import IconInfoMask from '@zego/static/svgs/icon-info-mask.svg';
import IconTickMask from '@zego/static/svgs/icon-tick-mask.svg';
import IconWarningMask from '@zego/static/svgs/icon-warning-mask.svg';

function statusToColors(status) {
  let colorFn;

  switch (status) {
    case UiStatus.Info:
    default:
      colorFn = infoColor;
      break;
    case UiStatus.Success:
      colorFn = successColor;
      break;
    case UiStatus.Warning:
      colorFn = warningColor;
      break;
    case UiStatus.Error:
      colorFn = errorColor;
      break;
  }
  return { bg: colorFn(1), fg: colorFn(6) };
}

function statusToIconSrc(status) {
  switch (status) {
    case UiStatus.Info:
    default:
      return IconInfoMask;
    case UiStatus.Success:
      return IconTickMask;
    case UiStatus.Warning:
      return IconWarningMask;
    case UiStatus.Error:
      return IconWarningMask;
  }
}

const statusToTitle = status => {
  switch (status) {
    case UiStatus.Info:
    default:
      return 'Information';
    case UiStatus.Success:
      return 'Success';
    case UiStatus.Warning:
      return 'Warning';
    case UiStatus.Error:
      return 'Error';
  }
};

export default function ScopedNotification({ status, message, className }) {
  const { fg, bg } = statusToColors(status);
  const src = statusToIconSrc(status);
  const title = statusToTitle(status);

  return (
    <div
      role="status"
      className={className}
      css={{
        backgroundColor: bg,
        padding: unit(2),
        borderRadius: unit(1),
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 500,
        color: fg,
        display: 'flex',
        alignItems: 'last baseline',
      }}
    >
      <span
        title={title}
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ReactSVG
          src={src}
          css={{
            display: 'block',
            color: fg,
            width: unit(3),
            height: unit(3),
            marginRight: unit(2),
          }}
        />
        <VisuallyHidden>{title}</VisuallyHidden>
      </span>
      <span css={{ marginTop: 2, marginBottom: 2 }}>{message}</span>
    </div>
  );
}
